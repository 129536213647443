import styled from 'styled-components';

import { useTranslation } from 'next-i18next';
import Theme from '../../styles/Theme';
import { DirectionProfiles } from '../../models/MapBox';
import { meterToString } from '../../utils/distance';
import { convertMinuteToString } from '../../utils/time';

import { IconDurationCapsuleSVG } from './svg/IconDurationCapsuleSVG';
import { IconOnBikeSVG } from './svg/IconOnBikeSVG';
import { IconOnFootSVG } from './svg/IconOnFootSVG';
import { IconOnVehiculeSVG } from './svg/IconOnVehiculeSVG';

interface Props {
	duration: number | null;
	directionProfile: DirectionProfiles;

	distanceDuration?: number;
	distance?: number;
}

const StyledContainer = styled.div`
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: 100%;
	color: ${(props) => props.theme.black};

	display: flex;
	align-items: center;

	padding: ${(props) => 16 / props.theme.fontSizeBase}rem 0;
	gap: ${(props) => 4 / props.theme.fontSizeBase}rem;
`;

const StyledDot = styled.span`
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 8 / props.theme.fontSizeBase}rem;
	line-height: 100%;
	opacity: 0.24;
	color: ${(props) => props.theme.black};
	margin: 0 ${(props) => 8 / props.theme.fontSizeBase}rem;
`;

export const MapOneCapsuleInfos = ({
	duration, distanceDuration, distance, directionProfile,
}: Props) => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContainer>
			{ duration && <>
				<IconDurationCapsuleSVG width={24} height={24} color={Theme.black} />

				{`${duration} ${t('common:min')}`}

				{ distanceDuration && distance && <StyledDot>•</StyledDot> }
			</>}

			{ distanceDuration && distance && <>
				{ directionProfile === 'walking' && <IconOnFootSVG width={24} height={24} color={Theme.black} /> }
				{ directionProfile === 'cycling' && <IconOnBikeSVG width={24} height={24} color={Theme.black} /> }
				{ directionProfile === 'driving' && <IconOnVehiculeSVG width={24} height={24} color={Theme.black} /> }
				{ `${convertMinuteToString(t, distanceDuration)} - ${meterToString(t, distance)}` }
			</>}
		</StyledContainer>
	);
};
