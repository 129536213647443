import { useAnimation, PanInfo, motion } from 'framer-motion';
import { PropsWithChildren, useCallback } from 'react';

import { useSreenHeight } from '../../custom/useScreenHeight';
import { MapStore } from '../../data/MapStore';

export const SwipeMovement = ({ children }: PropsWithChildren<unknown>) => {
	const screenHeight = useSreenHeight();
	const { heightOffsetButtons } = MapStore.useState((s) => s);
	const controls = useAnimation();

	const onDragEnd = useCallback((_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
		const percentOfScreen = Math.round((info.point.y / screenHeight) * 100);
		const shouldClose = info.velocity.y >= 0 && percentOfScreen >= 65;

		if (shouldClose) {
			controls.start('hidden');
		} else {
			controls.start('visible');
		}
	}, [controls, screenHeight]);

	return (
		<motion.div
			layout
			drag="y"
			onDragEnd={onDragEnd}
			initial="visible"
			animate={controls}
			variants={{
				visible: { y: 0 },
				hidden:  {
					y: heightOffsetButtons > 0
						? Math.round((heightOffsetButtons - 9) * 0.80)
						: 300,
				},
			}}
			dragConstraints={{ top: 0 }}
			dragElastic={0.2}
		>
			{ children }
		</motion.div>
	);
};
