import {
	FunctionComponent, useCallback, useEffect, useMemo, useRef,
} from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from 'next-i18next';
import Theme from '../../styles/Theme';
import { MapStore } from '../../data/MapStore';
import { useMapBoxDirections } from '../../data/Mapbox.swr';
import { Capsule } from '../../models/Capsule';
import { DirectionProfiles } from '../../models/MapBox';
import { MapButton } from './MapButton';
import { MapOneCapsuleInfos } from './MapOneCapsuleInfos';
import { SwipeMovement } from './SwipeMovement';
import { IconPointerSVG } from './svg/IconPointerSVG';

interface Props {
	capsule: Capsule;
	directionProfile: DirectionProfiles;

	onClickToGo: (capsule: Capsule) => void;
	onClickToPlay: (capsule: Capsule) => void;

	canBeHidden?: boolean;
}

const StyledOneCapsule = styled.div<{canBeHidden: boolean}>`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	background: ${(props) => props.theme.white};
	padding: ${(props) => 16 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 16 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;

	box-shadow: 0px -1px ${(props) => 8 / props.theme.fontSizeBase}rem rgba(34, 9, 2, 0.1);
	border-radius: ${(props) => 20 / props.theme.fontSizeBase}rem;

	${(props) => props.canBeHidden && css`
		padding: ${6 / props.theme.fontSizeBase}rem ${20 / props.theme.fontSizeBase}rem ${16 / props.theme.fontSizeBase}rem ${20 / props.theme.fontSizeBase}rem;
		border-radius: ${20 / props.theme.fontSizeBase}rem ${20 / props.theme.fontSizeBase}rem 0 0;
	`};
`;

const StyledHorizontalBar = styled.div`
	background: rgba(166, 166, 166, 0.4);
	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	height: ${(props) => 4 / props.theme.fontSizeBase}rem;
	margin: 0 auto ${(props) => 22 / props.theme.fontSizeBase}rem auto;
`;

const StyledTitle = styled.p`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: ${(props) => 19 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 24 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.black};
	margin: 0 0 ${(props) => 4 / props.theme.fontSizeBase}rem 0;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
`;

const StyledDescription = styled.p`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	opacity: 0.4;
	color: ${(props) => props.theme.black};
	margin: 0;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display:block;
`;

const StyledImageContainer = styled.div`
	position: relative;
	width: 100%;
	margin-top: ${(props) => 16 / props.theme.fontSizeBase}rem;

	img {
		border-radius: ${(props) => 8 / props.theme.fontSizeBase}rem;
		width: 100%;
		max-height: ${(props) => 127 / props.theme.fontSizeBase}rem;
		object-fit: cover;
	}

	div {
		border-radius: ${(props) => 8 / props.theme.fontSizeBase}rem;
		width: 100%;
		height: ${(props) => 127 / props.theme.fontSizeBase}rem;
		background: ${(props) => props.theme.textPrimary};
	}
`;

const StyledButtonContainer = styled.div`
	display: flex;
	gap: ${(props) => 8 / props.theme.fontSizeBase}rem;

	button {
		width: 50%;
	}
`;

const StyledAddress = styled.div`
	display: flex;
	gap: ${(props) => 4 / props.theme.fontSizeBase}rem;
	margin: ${(props) => 16 / props.theme.fontSizeBase}rem 0 0 0;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;

	p {
		margin: 0;
		padding: 0;
		font-family: 'Plus Jakarta Sans', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
		line-height: ${(props) => 24 / props.theme.fontSizeBase}rem;
		color: ${(props) => props.theme.black2};
	}
`;

const MapOneCapsuleWithoutEffect: FunctionComponent<Props> = ({
	capsule, onClickToGo, onClickToPlay, canBeHidden = true, directionProfile,
}: Props) => {
	const { t } = useTranslation(['common']);
	const ref = useRef<HTMLDivElement>(null);
	const { userLocation } = MapStore.useState((s) => s);

	const setOffsetHeight = useCallback(() => {
		MapStore.update((s) => {
			if (ref.current && ref.current.clientHeight > 0) {
				s.heightOffsetButtons = ref.current.clientHeight + 9;
			}
		});
	}, []);

	useEffect(() => {
		setOffsetHeight();

		return () => {
			MapStore.update((s) => {
				s.heightOffsetButtons = 0;
			});
		};
	}, [setOffsetHeight]);

	const points = useMemo(() => {
		if (capsule && userLocation) {
			return [
				[capsule.attributes.longitude, capsule.attributes.latitude],
				[userLocation.longitude, userLocation.latitude],
			];
		}
		return [];
	}, [capsule, userLocation]);

	const { data: mapBoxDirections } = useMapBoxDirections(directionProfile, points);

	return (
		<StyledOneCapsule ref={ref} canBeHidden={canBeHidden}>
			{ canBeHidden && <StyledHorizontalBar/> }

			<StyledTitle>{ capsule.attributes.title }</StyledTitle>
			<StyledDescription>
				{ capsule.attributes.subTitle ? capsule.attributes.subTitle : capsule.attributes.title }
			</StyledDescription>

			<StyledImageContainer>
				{ capsule.attributes.images.data && <img
					src={capsule.attributes.images.data[0].attributes.url}
					alt="image capsule"
					onLoad={setOffsetHeight}
				/> }
				{ !capsule.attributes.images.data && <div/> }
			</StyledImageContainer>

			<MapOneCapsuleInfos
				duration={capsule.attributes.duration}
				distance={mapBoxDirections?.distance}
				distanceDuration={mapBoxDirections?.duration}
				directionProfile={directionProfile}
			/>

			<StyledButtonContainer>
				<MapButton icon={directionProfile} onClick={() => onClickToGo(capsule)}>{ t('common:mapToGo') }</MapButton>
				<MapButton icon="play" light onClick={() => onClickToPlay(capsule)}>{ t('common:mapPlayCapsule') }</MapButton>
			</StyledButtonContainer>

			<StyledAddress>
				<IconPointerSVG width={24} height={24} color={Theme.black} />
				<p>
					{ capsule.attributes.address }
					{ (capsule.attributes.postalCode || capsule.attributes.city) && <>
						<br/>
						{ `${capsule.attributes.postalCode || ''} ${capsule.attributes.city || ''}` }
					</>}
				</p>
			</StyledAddress>
		</StyledOneCapsule>
	);
};

export const MapOneCapsule: FunctionComponent<Props> = (props: Props) => {
	if (props.canBeHidden) {
		return (
			<SwipeMovement>
				<MapOneCapsuleWithoutEffect {...props}/>
			</SwipeMovement>
		);
	}
	return (
		<MapOneCapsuleWithoutEffect {...props}/>
	);
};
