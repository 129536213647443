import useSWR, { SWRResponse } from 'swr';

import qs from 'qs';
import { httpGet } from '../lib/fetch';
import { Route } from '../models/Route';

import { StrapiGet } from './Strapi';

export function useRoutes(clientId: number, locale: string): SWRResponse<Route[], Error> {
	const query = qs.stringify({
		filters: {
			client: {
				id: {
					$eq: clientId,
				},
			},
		},
		sort: 'position',
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<Route[]>(
		[`/routes?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<Route[]>>(url);

			return data.data.map((route) => {
				if (localeSWR === 'en') {
					if (route.attributes.titleEn && route.attributes.titleEn.length > 0) {
						route.attributes.title = route.attributes.titleEn;
					}
					if (route.attributes.descriptionEn && route.attributes.descriptionEn.length > 0) {
						route.attributes.description = route.attributes.descriptionEn;
					}
					if (route.attributes.categoryEn && route.attributes.categoryEn.length > 0) {
						route.attributes.category = route.attributes.categoryEn;
					}
				}

				return route;
			});
		},
	);
}

export function useRoute(routeSlug: string, locale: string): SWRResponse<Route | null, Error> {
	const query = qs.stringify({
		filters: {
			slug: {
				$eq: routeSlug,
			},
		},
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<Route | null>(
		[`/routes?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<Route[]>>(url);

			if (data.data.length === 0) {
				return null;
			}

			const route = data.data[0];

			if (localeSWR === 'en') {
				if (route.attributes.titleEn && route.attributes.titleEn.length > 0) {
					route.attributes.title = route.attributes.titleEn;
				}
				if (route.attributes.descriptionEn && route.attributes.descriptionEn.length > 0) {
					route.attributes.description = route.attributes.descriptionEn;
				}
				if (route.attributes.categoryEn && route.attributes.categoryEn.length > 0) {
					route.attributes.category = route.attributes.categoryEn;
				}
			}

			return route;
		},
	);
}
